<template>
    <v-container>
        <v-row class="mt-16">
            <v-col cols="3">
                <v-row> Primary </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-sheet color="primary" height="100" width="100"></v-sheet>
                    </v-col>
                    <v-col cols="4">
                        <v-sheet color="primary lighten-1" height="100" width="100"></v-sheet>
                    </v-col>
                    <v-col cols="4">
                        <v-sheet color="primary lighten-2" height="100" width="100"></v-sheet>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="3" offset="1">
                <v-row> Secondary </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-sheet color="secondary" height="100" width="100"></v-sheet>
                    </v-col>
                    <v-col cols="4">
                        <v-sheet color="secondary lighten-1" height="100" width="100"></v-sheet>
                    </v-col>
                    <v-col cols="4">
                        <v-sheet color="secondary lighten-2" height="100" width="100"></v-sheet>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="3" offset="1">
                <v-row> tertiary </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-sheet color="tertiary" height="100" width="100"></v-sheet>
                    </v-col>
                    <v-col cols="4">
                        <v-sheet color="tertiary lighten-1" height="100" width="100"></v-sheet>
                    </v-col>
                    <v-col cols="4">
                        <v-sheet color="tertiary lighten-2" height="100" width="100"></v-sheet>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="mt-16">
            <v-col cols="3">
                <v-row> Accent </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-sheet color="accent" height="100" width="100"></v-sheet>
                    </v-col>
                    <v-col cols="4">
                        <v-sheet color="accent lighten-1" height="100" width="100"></v-sheet>
                    </v-col>
                    <v-col cols="4">
                        <v-sheet color="accent lighten-2" height="100" width="100"></v-sheet>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="3" offset="1">
                <v-row> Grey </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-sheet color="grey" height="100" width="100"></v-sheet>
                    </v-col>
                    <v-col cols="4">
                        <v-sheet color="grey lighten-1" height="100" width="100"></v-sheet>
                    </v-col>
                    <v-col cols="4">
                        <v-sheet color="grey lighten-2" height="100" width="100"></v-sheet>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="3" offset="1">
                <v-row> Darkblue </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-sheet color="darkblue" height="100" width="100"></v-sheet>
                    </v-col>
                    <v-col cols="4">
                        <v-sheet color="darkblue lighten-1" height="100" width="100"></v-sheet>
                    </v-col>
                    <v-col cols="4">
                        <v-sheet color="darkblue lighten-2" height="100" width="100"></v-sheet>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="mt-16">
            <v-col>
                <v-row> Objective </v-row>
                <v-row>
                    <v-col cols="1">
                        <v-sheet color="objective" height="100" width="100"></v-sheet>
                    </v-col>
                    <v-col cols="1">
                        <v-sheet color="objective darken-1" height="100" width="100"></v-sheet>
                    </v-col>
                    <v-col cols="1">
                        <v-sheet color="objective darken-2" height="100" width="100"></v-sheet>
                    </v-col>
                    <v-col cols="1">
                        <v-sheet color="objective darken-3" height="100" width="100"></v-sheet>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Color",
};
</script>

<style scoped></style>
